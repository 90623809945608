import { RefObject, useEffect, useState } from 'react'

const useOverflow = (
  ref: RefObject<HTMLElement>,
  title?: NonNullable<React.ReactNode>,
) => {
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    const checkOverflow = () => {
      if (ref.current) {
        setIsOverflowing(ref.current.scrollWidth > ref.current.clientWidth)
      }
    }

    checkOverflow()

    window.addEventListener('resize', checkOverflow)

    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [ref, title])

  return isOverflowing
}

export default useOverflow
