import socketIO from 'socket.io-client'
import socketWildcard from 'socketio-wildcard'
import logError from '@utils/logError'
import { getAPIUrl } from './utils/url'

const isE2E = process.env.REACT_APP_E2E === 'true'

const patch = socketWildcard(socketIO.Manager)
let socket

export default function getSocketConnection() {
  if (!socket || socket.disconnected) {
    const apiUrl = isE2E ? 'http://localhost:3000' : getAPIUrl()

    socket = socketIO.connect(apiUrl, {
      transports: ['websocket'],
    })

    socket.on('connect_error', () => {
      console.log('Socket connection failed')
      logError('socket connect_error')
      window.localStorage.setItem('WS_connect_error', 'websocket-error')
      if (socket) {
        socket.close()
        socket = undefined
      }
    })

    socket.on('connect', () => {
      // eslint-disable-next-line no-console
      //console-log-checker-ignore
      console.log('Socket connection established')

      // The socket should always exists at this point but better to prevent
      // than break in front of the user because it is happening
      if (socket) {
        window.localStorage.removeItem('WS_connect_error')
        socket.on('disconnect', () => {
          if (socket) {
            socket.close()
          }
          socket = undefined
        })
      }
    })

    patch(socket)
  }

  return socket
}
