import * as Sentry from '@sentry/browser'

/**
 * Gracfully handle the errors and report them
 * to sentry
 *
 * @prop {Error}  err     - Error to report
 * @prop {Object} context - Additional informations
 */
export default function logError(err, context) {
  Sentry.captureException(err, {
    extra: context,
  })

  /* eslint-disable no-console */
  //console-log-checker-ignore
  console.error(err)
  // console.log(`Error context: ${JSON.stringify(context, null, 2)}`)
  /* eslint-enable no-console */
}
